import { Tooltip } from "antd";
import i18n, { languageKeys } from "../../../i18n";
import TrangThaiGoiDV from "../components/TrangThaiGoiDV";

const ellipsisProps = {
  ellipsis: true,
  render: (data) => {
    return (
      <Tooltip placement="topLeft" title={data} zIndex={1}>
        {data === "null" || data === "undefined" || !data ? "" : data}
      </Tooltip>
    );
  },
};

const StatusValue = {
  CHUA_HOAT_DONG: "CHUA_HOAT_DONG",
  HOAT_DONG: "HOAT_DONG",
  TAM_NGUNG: "TAM_NGUNG",
  SAP_HET_HAN: "SAP_HET_HAN",
  HET_HAN: "HET_HAN",
  HOAN_THANH: "HOAN_THANH",
};

export const DataIndexOfColumn = {
  MA_GOI: "MA_GOI_DV",
  TEN_GOI: "TEN_GOI_DV",
  LOAI_GOI: "TEN_LOAI_GOI",
  NGAY_TAO: "NGAY_TAO",
  GIA_BAN_GOI: "GIA_GOI_DV",
  NGAY_HIEU_LUC: "NGAY_HIEU_LUC",
  NGAY_HET_HAN: "NGAY_HET_HAN",
  SO_NGAY_SU_DUNG: "NGAY_SU_DUNG",
  TRANG_THAI: "TRANG_THAI",
  GIO_TAO: 'THOI_GIAN_TAO'
};

export const DataIndexOfServiceTable = {
  MA_DV: 'MA_DICHVU',
  TEN_DV: 'TEN_DICHVU',
  SL: 'SL',
  LOAI_GIA: 'LOAI_GIA',
  DON_GIA: 'GIA_THU_PHI',
  THANH_TIEN: 'THANH_TIEN',
  DON_GIA_PHAN_BO: 'DON_GIA_PHAN_BO',
  THANH_TIEN_PHAN_BO: 'THANH_TIEN_PHAN_BO',
  TY_LE: 'TY_LE',
  NOI_THUC_HIEN: 'NOI_THUC_HIEN',
  NGUOI_CAP_NHAT: 'NGUOI_CAP_NHAT',
  NGUOI_CAP_NHAT_ID: 'NGUOI_CAP_NHAT_ID',
  NHANSU_ID: 'NHANSU_ID',
  NGAY_CAP_NHAT: 'NGAY_CAP_NHAT',
  TEN_KHOA_PHONG: 'TEN_KHOA_PHONG',
  KHOA_PHONG_ID: 'PHONG_THUC_HIEN_ID',
  GIO_CAP_NHAT: 'GIO_CAP_NHAT',
  IS_CAP_NHAT: 'IS_CAP_NHAT',
}

export const StatusList = [
  {
    label: i18n.t(languageKeys.chua_hoat_dong),
    value: StatusValue.CHUA_HOAT_DONG,
  },
  {
    label: i18n.t(languageKeys.hoat_dong),
    value: StatusValue.HOAT_DONG,
  },
  {
    label: i18n.t(languageKeys.tam_ngung),
    value: StatusValue.TAM_NGUNG,
  },
  {
    label: i18n.t(languageKeys.sap_het_han),
    value: StatusValue.SAP_HET_HAN,
  },
  {
    label: i18n.t(languageKeys.het_han),
    value: StatusValue.HET_HAN,
  },
];

export const UsageStatusList = [
  {
    label: i18n.t(languageKeys.common_Hoan_thanh),
    value: StatusValue.HOAN_THANH,
  },
  {
    label: i18n.t(languageKeys.hoat_dong),
    value: StatusValue.HOAT_DONG,
  },
  {
    label: i18n.t(languageKeys.tam_ngung),
    value: StatusValue.TAM_NGUNG,
  },
  {
    label: i18n.t(languageKeys.sap_het_han),
    value: StatusValue.SAP_HET_HAN,
  },
  {
    label: i18n.t(languageKeys.het_han),
    value: StatusValue.HET_HAN,
  },
];

export const PacketTypeListColumn = [
  {
    key: "MA_GOI",
    title: i18n.t(languageKeys.ma_goi),
    dataIndex: DataIndexOfColumn.MA_GOI,
    width: 110,
    ellipsis: true
  },
  {
    key: "TEN_GOI",
    title: i18n.t(languageKeys.ten_goi),
    dataIndex: DataIndexOfColumn.TEN_GOI,
    width: 200,
  },
  {
    key: "LOAI_GOI",
    title: i18n.t(languageKeys.loai_goi),
    dataIndex: DataIndexOfColumn.LOAI_GOI,
    width: 120,
  },
  {
    key: "NGAY_TAO",
    title: i18n.t(languageKeys.ngay_tao),
    dataIndex: DataIndexOfColumn.NGAY_TAO,
    width: 140,
  },
  {
    key: "GIA_BAN_GOI",
    title: i18n.t(languageKeys.gia_ban_goi),
    dataIndex: DataIndexOfColumn.GIA_BAN_GOI,
    width: 110,
    ...ellipsisProps,
  },
  {
    key: "NGAY_HIEU_LUC",
    title: i18n.t(languageKeys.ngay_hieu_luc),
    dataIndex: DataIndexOfColumn.NGAY_HIEU_LUC,
    width: 140,
  },
  {
    key: "NGAY_HET_HAN",
    title: i18n.t(languageKeys.ngay_het_han),
    dataIndex: DataIndexOfColumn.NGAY_HET_HAN,
    width: 120,
  },
  {
    key: "SO_NGAY_SU_DUNG",
    title: i18n.t(languageKeys.so_ngay_su_dung),
    dataIndex: DataIndexOfColumn.SO_NGAY_SU_DUNG,
    ...ellipsisProps,
    width: 140,
  },
  {
    key: "TRANG_THAI",
    title: i18n.t(languageKeys.trang_thai),
    width: 120,
    render: (_, record) => {
      return <TrangThaiGoiDV state={record[DataIndexOfColumn.TRANG_THAI]} />;
    },
  },
];

export const PacketServiceInfo = {
  MA_GOI: "MA_GOI_DV",
  TEN_GOI: "TEN_GOI_DV",
  LOAI_GOI: "LOAI_GOI_DV_ID",
  GIA_GOI_DV: "GIA_GOI_DV",
  NGAY_HIEU_LUC: "NGAY_HIEU_LUC",
  NGAY_HET_HAN: "NGAY_HET_HAN",
  SO_NGAY_SU_DUNG: "NGAY_SU_DUNG",
  NGAY_TAO: "NGAY_TAO",
  NGUOI_TAO: "NGUOI_TAO",
  MO_TA: "MO_TA",
  GHI_CHU: "GHI_CHU",
  NGAY_CAP_NHAT: 'NGAY_CAP_NHAT',
  NGUOI_CAP_NHAT: 'NGUOI_CAP_NHAT',
  DUNG_GOI: 'DUNG_GOI',
  NHOM_GOI: 'NHOM_GOI',
  SO_LAN_TELEMEDICINE: 'SO_LAN_TELEMEDICINE'
};


export const ActionType = {
  create: 'CREATE',
  edit: 'EDIT',
  copy: 'COPY'
}

export const keyNhomDoiTuongNguoiDung = {
  BSFulltime: 'BACSI_FULLTIME',
  BSParttime: 'BACSI_PARTTIME',
  chuyen_gia: 'CHUYEN_GIA',
  CSKCB: 'CSKCB',
  nhan_vien: 'NHANVIEN',
  sales: 'SALES',
  khach_hang: 'KHACH_HANG',
  CTV: 'CTV',
  bacsi_ctv: 'BACSI_CTV',
};

export const BundleGroup = [
  {
    label: i18n.t(languageKeys.goi_kham),
    value: 'DICH_VU',
    tooltip: i18n.t(languageKeys.goi_dung_1_lan),
  },
  {
    label: i18n.t(languageKeys.goi_dieu_tri),
    value: 'GOI_LIEU_TRINH',
    tooltip: i18n.t(languageKeys.goi_dung_nhieu_ngay),
  },
]